
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { getOrgInfoByOId, logout, updatePassword } from '@/api/login';
import { template } from '@/router/modules/template';
import { Service } from '@/router/modules/serviceList';
import { myOrders } from '@/router/modules/myOrders';
@Component({
  name: 'Navbar',
  components: {
  },
})
export default class extends Vue {
  @Getter('user') getUser: any;
  @Action('setUser') setUser: any;
  @Getter('routers') getRouters: any;
  // 计算属性
  get RoutersData () {
    const enableStudentToTeacher = this.orgInfo?.entries?.split(',').map(Number).includes(10);
    let subMenuItems = Service.concat(myOrders);
    if (enableStudentToTeacher) {
      // 加上 template
      subMenuItems = subMenuItems.concat(template);
    }
    return subMenuItems;
  }

  get orgId () {
    return this.$store.state.user?.orgs[0]?.orgId || 0;
  }
  orgInfo: any = {};
  async created () {
    if (!this.orgId) {
      return;
    }
    if (!this.$store.state.OrgInfo) {
      this.orgInfo = await getOrgInfoByOId({ orgId: this.orgId });
      this.$store.commit('SET_ORGINFO', this.orgInfo);
    } else {
      this.orgInfo = this.$store.state.OrgInfo;
    }
  }

  get avatar () {
    return require('@/assets/avatar.gif');
  }

  get nickname () {
    if (this.getUser) {
      return this.getUser.nickname;
    }
    return '';
  }

  public async logout () {
    await logout();
    window.location.replace('signin.html');
    this.setUser(null);
  }
  async goPortal () {
    window.location.replace('signin.html');
  }

  dialogVisible = false
  dialogForm = {
    oldPassword: '',
    newPassword: '',
    reNewPassword: ''
  }
  async handleUpdatePassword () {
    if (!this.dialogForm.oldPassword || !this.dialogForm.newPassword || !this.dialogForm.reNewPassword) {
      this.$message.error('请填写完整信息');
      return;
    }
    if (this.dialogForm.newPassword !== this.dialogForm.reNewPassword) {
      this.$message.error('两次输入的密码不一致');
      return;
    }
    try {
      await updatePassword({
        oldPassword: this.dialogForm.oldPassword,
        newPassword: this.dialogForm.newPassword
      });
      this.$message.success('修改成功,请重新登录');
      this.logout();
    } catch (e) {
      this.$message.error((e as any).statusInfo);
    }
  }
}
