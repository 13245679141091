
// import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';
import { AppMain, Navbar } from './components';
// import { Action, Getter } from 'vuex-class';
import { own, updatePassword } from '@/api/login';

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar
  },
})
export default class extends Vue {
  contactOn = false
  hasDangerPassword = false
  oPassword = ''
  nPassword = ''
  nPassword2 = ''

  async mounted () {
    // 对不是@hexin.im的用户进行密码检查 ( 若账号密码一致，强提醒用户修改 )
    // const user = await own();
    let user;
    if (this.$store.state.user) {
      user = this.$store.state.user;
    } else {
      user = await own();
    }

    if (!(user.accounts[0].username).includes('@hexin.im')) {
      this.hasDangerPassword = user.hasDangerPassword;
    }
  }
  async changePassword () {
    if (!this.oPassword) {
      return this.$message.error('旧密码不能为空！');
    }
    if (!this.nPassword) {
      return this.$message.error('新密码不能为空！');
    }
    if (this.oPassword === this.nPassword) {
      return this.$message.error('新密码不能和旧密码相同！');
    }
    if (this.nPassword !== this.nPassword2) {
      return this.$message.error('两次输入新密码不相同！');
    }
    try {
      await updatePassword({
        newPassword: this.nPassword,
        oldPassword: this.oPassword
      });
      this.$message.success('修改成功！');
      this.hasDangerPassword = false;
    } catch (e) {
      return this.$message.error(e.statusInfo);
    }
  }
  showContactList () {
    this.contactOn = !this.contactOn;
  }
}
